.service-login {
    &-container {
        min-width: 200px;
    }

    &-row {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-info {
        margin: 10px 30px;
    }

    &-img {
        width: 45px; 
        margin-top: 2px;
    }
}