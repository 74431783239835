.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.tooltip-header {
  text-transform: none !important;
}

.card-subtitle {
  white-space: pre;
}

.card-img {
  object-fit: contain;
}

.btn-outline-secondary:not(:disabled) {
  &:hover {
    color: $white;
  }
  &:active {
    color: $white;
  }
}
