.cr-footer {
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0.5rem 1rem;
  }
}
