// override bootstrap 4 variables

// colors
$white: #fff;
$black: #000;
$primary-color: #003e70;
$secondary-color: #ef9029;
$danger-color: #c91e00;

$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color,
  "success": #407941,
  "danger": $danger-color,
  "warning": #fbb034,
  "info": #206f9f,
  "taskCompleted": #004680,
  "taskPending": #3397ac,
  "taskCurrent": #36997a,
  "taskOverdue": #e92b34,
  "taskFuture": #2d3338,
  "importantUpdates": #3379ac,
  "emptyProgressbar" : $white,
  "mainTheme": #27567d
);

$enable-gradients: true;

// card
$card-cap-bg: $white;


// sidebar
$cr-sidebar-width: 224px;
