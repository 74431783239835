.task-modal {
  margin: 1rem 2rem;

  &__header {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin: 1rem 1rem 0;
  }

  &__footer {
      display: flex;
      justify-content: space-between;
      margin: 0 3rem;
  }
}
