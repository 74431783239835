.cr-widget {
  display: flex;
  flex-direction: row;

  &__icon {
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
  }

  &__date {
    max-width: 200px;
    margin-left: auto;
    text-align: center;
  }
}

.cr-widget-subtask {
  filter: brightness(1.3);
}
