// source: https://github.com/daneden/animate.css

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  transform-origin: top center;
  animation-name: swing;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

canvas {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

.progress-button {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 190px;
  height: 40px;
  padding: 0 10px;
  overflow: hidden;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #f4f7ff;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  transform: translate(-50%, -50%);
  -webkit-tap-highlight-color: transparent;

  &::before {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    content: "";
    background: #1f2335;
    border-radius: 50px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .4) inset;
    transition: width .2s cubic-bezier(.39, 1.86, .64, 1) .3s;
  }
}
