header {
  position: fixed;
  width: 100vw;
  transition: width .2s ease-in;
  z-index: 10;
}

.cr-header {
  margin-bottom: $spacer * .5;
  border-bottom: $border-width solid $border-color;

  &__with-sidebar {
    width: calc(100% - 224px);
    transition: width .3s ease-in; 
  }

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }
}

.bs-popover-bottom > .arrow::after{
  border-bottom-color: #27567d;
}
