.card-row {
  margin-bottom: 1rem;
}

.form-input-gap {
  gap: 1rem;
}

.padding-0 {
  padding: 0;
}

.user-card-item {
  align-items: center;
  padding: .3rem 0;
  background-color: #f7f6f6;
}

.row-spinner {
  justify-content: center;
  padding: 1rem;
}

.cardTitle {
  font-size: "x-large";
}

.progress_card_center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_card_no_padding{
  padding: 0;
  padding-right: 0;
  padding-left: 0;
}

.outstanding-invoices {
  display: flex;
  font-size: 12px;
}
.outstanding-invoices-header {
  display: flex;
}
.outstanding-invoices-header div{
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 800;
}

.oi-unpaid {
  min-width: 130px;
}
.oi-due {
  width: 70px;
}
.oi-link {
  margin-right: 0;
}
