@import "./variables";

.not-found-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found-error {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.not-found-number {
  font-size: 15rem;
  font-weight: 900;
  line-height: 1;
}

.not-found-illustration {
  position: relative;
  width: 12.2rem;
  margin: 0 2.1rem;
}

.not-found-circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 12.2rem;
  height: 11.4rem;
  background-color: $black;
  border-radius: 50%;
}

.not-found-clip {
  position: absolute;
  bottom: .3rem;
  left: 50%;
  width: 12.5rem;
  height: 13rem;
  overflow: hidden;
  border-radius: 0 0 50% 50%;
  transform: translateX(-50%);
}

.not-found-paper {
  position: absolute;
  bottom: -.3rem;
  left: 50%;
  width: 9.2rem;
  height: 12.4rem;
  background-color: $white;
  border: .3rem solid $black;
  border-radius: .8rem;
  transform: translateX(-50%);
}

.not-found-paper::before {
  position: absolute;
  top: -.7rem;
  right: -.7rem;
  width: 1.4rem;
  height: 1rem;
  content: "";
  background-color: $white;
  border-bottom: .3rem solid $black;
  transform: rotate(45deg);
}

.not-found-face {
  position: relative;
  margin-top: 2.3rem;
}

.not-found-eyes {
  position: absolute;
  top: 0;
  left: 2.4rem;
  width: 4.6rem;
  height: .8rem;
}

.not-found-eye {
  position: absolute;
  bottom: 0;
  width: .8rem;
  height: .8rem;
  background-color: $black;
  border-radius: 50%;
  animation-name: eye;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.not-found-eye-left {
  left: 0;
}

.not-found-eye-right {
  right: 0;
}

@keyframes eye {
  0% {
    height: .8rem;
  }
  50% {
    height: .8rem;
  }
  52% {
    height: .1rem;
  }
  54% {
    height: .8rem;
  }
  100% {
    height: .8rem;
  }
}

.not-found-rosyCheeks {
  position: absolute;
  top: 1.6rem;
  width: 1rem;
  height: .2rem;
  background-color: #fdabaf;
  border-radius: 50%;
}

.not-found-rosyCheeks-left {
  left: 1.4rem;
}

.not-found-rosyCheeks-right {
  right: 1.4rem;
}

.not-found-mouth {
  position: absolute;
  top: 3.1rem;
  left: 50%;
  width: 1.6rem;
  height: .2rem;
  background-color: $black;
  border-radius: .1rem;
  transform: translateX(-50%);
}

.not-found-text {
  margin-top: 2rem;
  font-weight: 300;
  color: $black;
}

.not-found-button {
  padding: 1.2rem 3rem;
  margin-top: 3rem;
  color: $white;
  background: #003e70 linear-gradient(180deg, #265b85, #003e70) repeat-x;
  border-radius: 3px;
}

.not-found-button:hover {
  color: $white;
  text-decoration: none;
  background: #00294a linear-gradient(180deg, #264965, #00294a) repeat-x;
}
