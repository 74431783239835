.cr-admin-dashboard {
  & .card {
    max-width: 1300px;
    padding: 1rem;
    margin: 1rem;
  }
}

.cr-project-users {
  & .card {
    padding: 0.5rem;
  }
}

.cr-user-invitation {
  & .card {
    padding: 0.5rem;
  }

  & form {
    margin: 1rem;
  }
}
