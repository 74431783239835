@import "../variables";

.no-access-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-access-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  color: $black;
}

.no-access-card-title {
  font-size: 30px;
}